import { Loader } from 'component/customComponents/loader/loader';
import React, { ComponentProps, ReactElement, Suspense, lazy } from 'react';

const LazyStudentTest = lazy(() =>
  import('./studentTest')
    .then((module) => ({ default: module.StudentTest }))
    .catch(() => {
      document.location.reload();
      return { default: (): JSX.Element => <></> };
    })
);

export const StudentTest = (
  props: ComponentProps<typeof LazyStudentTest>
): ReactElement => (
  <Suspense fallback={<Loader />}>
    <LazyStudentTest {...props} />
  </Suspense>
);
