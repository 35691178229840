import { FC, ReactElement, createContext, useContext, useState } from 'react';

type SidebarContextValue = {
  isSidebarOpen: boolean;
  closeSidebar: () => void;
  openSidebar: () => void;
};

const SidebarContext = createContext<SidebarContextValue>(
  {} as SidebarContextValue
);

export const SidebarContextProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeSidebar = () => {
    setIsOpen(false);
  };
  const openSidebar = () => {
    setIsOpen(true);
  };

  const isSidebarOpen = isOpen;

  return (
    <SidebarContext.Provider
      value={{ isSidebarOpen, closeSidebar, openSidebar }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () =>
  useContext<SidebarContextValue>(SidebarContext);
