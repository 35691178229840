import 'component/appRoot/appRoot.scss';

import { PAGES } from 'constants/availablePages';

import { useQuery } from '@tanstack/react-query';
import { API } from 'api/api';
import { Footer } from 'component/appRoot/footer/footer';
import { Header } from 'component/appRoot/header/header';
import { Sidebar } from 'component/appRoot/Sidebar';
import { BreadcrumbContextProvider } from 'component/context/BreadcrumbContext';
import { userContext } from 'component/context/context';
import { SidebarContextProvider } from 'component/context/SidebarContext/SidebarContext';
import { Template } from 'component/extendedTest/template';
import { Test } from 'component/extendedTest/test';
import { StudentTest } from 'component/extendedTest/test/StudentTest';
import { ActivityType } from 'component/extendedTest/types';
import { Course } from 'pages/course';
import { StudentCourse } from 'pages/course/StudentCourse';
import { NewLessonViewer } from 'pages/lessonViewer';
import { StudentLesson } from 'pages/lessonViewer/StudentLesson';
import { Main } from 'pages/main';
import { StudentMain } from 'pages/main/StudentMain';
import { StudentsWorks } from 'pages/studentWorks';
import { TechnicalRequirements } from 'pages/technicalRequirements';
import { WorksToCheck } from 'pages/worksToCheck';
import React, { memo, useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { RightValues } from 'types/rights';
import { axiosSelect } from 'utils';

const availablePages = PAGES;

export const AppRoot = memo(({ onLogout }: { onLogout: () => void }) => {
  const { type, id } = useContext(userContext);
  const isTeacher = type?.hasRightsOf(RightValues.Teacher);
  const isAdmin = type?.hasRightsOf(RightValues.Admin);
  const location = useLocation();

  const intervalMs = 1000 * 60 * 5;

  const { data: totalUncheckedTests } = useQuery({
    queryKey: ['totalUncheckedTests', id],
    queryFn: () => API.ExtendedTests.total_tests_to_check(id as number),
    enabled: type?.hasRightsOf(RightValues.Teacher),
    refetchInterval: intervalMs,
    select: axiosSelect,
  });
  const { data: totalUncheckedStudentsTests } = useQuery({
    queryKey: ['totalUncheckedStudentsTests', id],
    queryFn: () => API.ExtendedTests.get_total_unchecked_tests(id as number),
    enabled: !type?.hasRightsOf(RightValues.Teacher),
    refetchInterval: intervalMs,
    select: axiosSelect,
  });
  return (
    <SidebarContextProvider>
      <BreadcrumbContextProvider>
        <div
          style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
          className={`${isAdmin || isTeacher ? null : 'user-select-none'} `}
        >
          <Sidebar
            uncheckedTests={totalUncheckedTests?.total}
            uncheckedStudentsTests={totalUncheckedStudentsTests?.total}
          />
          {(type?.role === 'admin' ||
            type?.role === 'teacher' ||
            !location.pathname.startsWith('/main')) && (
            <Header
              onLogout={onLogout}
              uncheckedTests={totalUncheckedTests?.total}
              uncheckedStudentsTests={totalUncheckedStudentsTests?.total}
            />
          )}
          <div className="AppRoot__Body">
            <Container style={{ height: '100%', minWidth: '360px' }}>
              <Switch>
                <Route
                  path="/main"
                  render={() =>
                    type?.role !== 'student' ? (
                      <Main />
                    ) : (
                      <StudentMain
                        onLogout={onLogout}
                        uncheckedTests={totalUncheckedTests?.total}
                        uncheckedStudentsTests={
                          totalUncheckedStudentsTests?.total
                        }
                      />
                    )
                  }
                />
                {availablePages
                  .filter(({ minimalRights }) =>
                    type?.hasRightsOf(minimalRights)
                  )
                  .map(({ link, render }) => (
                    <Route key={link} path={link} render={render} />
                  ))}
                <Route
                  path="/lesson/:courseId/:subjectId/:lessonId"
                  render={() =>
                    type?.role !== 'student' ? (
                      <NewLessonViewer />
                    ) : (
                      <StudentLesson />
                    )
                  }
                />
                <Route
                  path="/course/:courseId"
                  render={() =>
                    type?.role !== 'student' ? <Course /> : <StudentCourse />
                  }
                />
                <Route path="/uncheckedTests" render={() => <WorksToCheck />} />
                <Route
                  path="/extendedTests/:courseId/:subjectId/:lessonId/new"
                  render={() => (
                    <Test
                      // @ts-ignore
                      activityType={ActivityType.CREATING}
                    />
                  )}
                />
                <Route
                  path="/extendedTests/:courseId/:subjectId/:lessonId/:id"
                  render={() =>
                    type?.role !== 'student' ? (
                      <Test
                        // @ts-ignore
                        activityType={
                          isTeacher
                            ? ActivityType.CREATING
                            : ActivityType.PASSING
                        }
                      />
                    ) : (
                      <StudentTest activityType={ActivityType.PASSING} />
                    )
                  }
                />
                <Route
                  path="/templates/:courseId/:subjectId/:lessonId/new"
                  render={() => <Template />}
                />
                <Route
                  path="/templates/:courseId/:subjectId/:lessonId/:id"
                  render={() => <Template />}
                />
                <Route
                  path="/works/:courseId/:subjectId/:lessonId/:testId"
                  render={() => <StudentsWorks />}
                />
                <Route
                  path="/technical-requirements"
                  render={() => <TechnicalRequirements />}
                />
                <Route render={() => <Redirect to="/main" />} />
              </Switch>
            </Container>
            {(type?.role === 'admin' ||
              type?.role === 'teacher' ||
              !location.pathname.startsWith('/main')) && <Footer />}
          </div>
        </div>
      </BreadcrumbContextProvider>
    </SidebarContextProvider>
  );
});
