export type TRoles = 'Admin' | 'Teacher' | 'Student' | 'Guest';
export type TRight = {
  value: 'admin' | 'teacher' | 'student' | 'guest' | undefined;
  priority: number;
};
export type TRights = Record<TRoles, TRight>;

const RightValues: TRights = {
  Admin: {
    value: 'admin',
    priority: 0,
  },
  Teacher: {
    value: 'teacher',
    priority: 1,
  },
  Student: {
    value: 'student',
    priority: 2,
  },
  Guest: {
    value: 'guest',
    priority: 3,
  },
};

class Rights {
  private rights: TRight | null = null;

  userRole: TRight['value'] | undefined;

  constructor(s: string) {
    Object.entries(RightValues).forEach(([value, right]) => {
      if (s.toLowerCase() === value.toLowerCase()) {
        this.rights = right;
        this.userRole = right.value;
      }
    });
    // Не совесем ясно что делать с таким типом доступа
    if (s.toLowerCase() === 'employee') {
      this.rights = RightValues.Student;
      this.userRole = RightValues.Guest.value;
    }
    if (!this.rights) {
      throw new Error('Unexpected rights');
    }
  }

  hasRightsOf(value: TRight) {
    if (!this.rights) return false;

    return this.rights.priority <= value.priority;
  }

  get role() {
    return this.userRole;
  }
}

export { Rights, RightValues };
