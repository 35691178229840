import 'component/appRoot/Sidebar/sidebar.scss';

import { NavigationMenu } from 'component/appRoot/NavigationMenu';
import { useSidebarContext } from 'component/context/SidebarContext';
import { useSubstrateContext } from 'component/context/SubstrateContext/SubstrateContext';
import { useBreakpoint } from 'hooks';
import React, { FC } from 'react';
import { Sidebar as RawSidebar } from 'react-pro-sidebar';

type SidebarProps = {
  uncheckedTests?: number;
  uncheckedStudentsTests?: number;
  isNotificationsEnabled?: boolean;
};

export const Sidebar: FC<SidebarProps> = ({
  uncheckedTests,
  uncheckedStudentsTests,
}) => {
  const { closeSidebar, isSidebarOpen } = useSidebarContext();
  const { closeSubstrate } = useSubstrateContext();
  const breakpoint = useBreakpoint();
  return (
    <>
      {!(breakpoint === 'xs') && (
        <RawSidebar
          className="raw-sidebar"
          width={'80px'}
          backgroundColor="#fff"
          onBackdropClick={() => closeSidebar()}
          toggled={isSidebarOpen}
          customBreakPoint="1199px"
          style={{ zIndex: 100000, position: 'fixed' }}
        >
          <NavigationMenu
            onItemClick={() => {
              closeSidebar();
              closeSubstrate();
            }}
            uncheckedStudentsTests={uncheckedStudentsTests}
            uncheckedTests={uncheckedTests}
          />
        </RawSidebar>
      )}
    </>
  );
};
