import check from 'assets/images/menuItems/check.svg';
import journal from 'assets/images/menuItems/journal.svg';
import timetable from 'assets/images/menuItems/timetable.svg';
import { JournalRouter } from 'pages/journal/journalRouter/journalRouter';
import { QuestionsList } from 'pages/questionsList';
import { StudentCalender } from 'pages/studentCalendar';
import { StudentPersonalAccount } from 'pages/studentPersonalAccount';
import { UserExamStats } from 'pages/userExamStats';
import { UserSelector } from 'pages/userSelector';
import React, { ReactElement } from 'react';
import { GraphUp, ListColumns, People } from 'react-bootstrap-icons';
import { RightValues, TRight } from 'types/rights';

export type TPageDefinition = {
  name: string;
  link: string;
  isShow: boolean;
  icon?: ({ width, height }: { width: string; height: string }) => ReactElement;
  minimalRights: TRight;
  renderFor: TRight['value'][];
  render: () => ReactElement;
};

export const PAGES: TPageDefinition[] = [
  {
    name: 'Главная страница',
    link: '/main',
    isShow: false,
    renderFor: ['admin', 'teacher', 'student'],
    minimalRights: RightValues.Student,
    render: () => <></>,
  },
  {
    name: 'Статистика',
    link: '/userStats',
    isShow: true,
    minimalRights: RightValues.Teacher,
    renderFor: ['admin', 'teacher'],
    icon: (props) => <GraphUp {...props} color="#389AF4" />,
    render: () => <UserExamStats />,
  },
  {
    name: 'Журнал',
    link: '/journal',
    isShow: true,
    renderFor: ['admin', 'teacher', 'student'],
    icon: ({ height, width }) => (
      <img alt="img" src={journal} style={{ width, height }} />
    ),
    minimalRights: RightValues.Guest,
    render: () => <JournalRouter />,
  },
  {
    name: 'Расписание',
    link: '/timetable',
    isShow: true,
    minimalRights: RightValues.Student,
    renderFor: [],
    icon: ({ height, width }) => (
      <img alt="img" src={timetable} style={{ width, height }} />
    ),
    render: () => <StudentCalender />,
  },
  {
    name: 'Домашняя работа',
    link: '/studentPersonalAccount',
    isShow: true,
    minimalRights: RightValues.Student,
    renderFor: ['student'],
    icon: ({ height, width }) => (
      <img alt="img" src={check} style={{ width, height }} />
    ),
    render: () => <StudentPersonalAccount />,
  },
  {
    name: 'Смена пользователя',
    link: '/userSelector',
    isShow: true,
    renderFor: ['admin'],
    icon: (props) => <People {...props} color="#389AF4" />,
    minimalRights: RightValues.Admin,
    render: () => <UserSelector />,
  },
  {
    name: 'Тэгирование вопросов',
    link: '/questionsList',
    isShow: true,
    renderFor: ['admin'],
    icon: (props) => <ListColumns {...props} color="#389AF4" />,
    minimalRights: RightValues.Admin,
    render: () => <QuestionsList />,
  },
];
