import { AxiosResponse } from 'axios';

const unixTimeToString = (timestamp: number) => {
  const date = new Date(timestamp);

  const day = date.getDate();
  const month = [
    'янв',
    'фев',
    'мар',
    'апр',
    'май',
    'июн',
    'июл',
    'авг',
    'сен',
    'окт',
    'ноя',
    'дек',
  ][date.getMonth()];
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = `0${date.getMinutes()}`.substr(-2);
  const seconds = `0${date.getSeconds()}`.substr(-2);

  return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
};

const millisToMS = (millis: number) => {
  const inSeconds = Math.floor(millis / 1000);
  const minutes = Math.floor(inSeconds / 60);
  const seconds = inSeconds % 60;
  return `${minutes}:${`0${seconds}`.substr(-2)}`;
};

const millisToHMS = (millis: number) => {
  const inSeconds = Math.floor(millis / 1000);
  const inMinutes = Math.floor(inSeconds / 60);
  const inHours = Math.floor(inMinutes / 60);

  const minutes = inMinutes % 60;
  const seconds = inSeconds % 60;

  return `${inHours}:${`0${minutes}`.substr(-2)}:${`0${seconds}`.substr(-2)}`;
};

const getDayMonth = (time: string | number) => {
  const date = new Date(time);
  const day = date.getDate();
  const month = [
    'янв',
    'фев',
    'мар',
    'апр',
    'май',
    'июн',
    'июл',
    'авг',
    'сен',
    'окт',
    'ноя',
    'дек',
  ][date.getMonth()];
  return `${day} ${month}`;
};

export const axiosSelect = <T extends any>(response?: AxiosResponse<T>) =>
  response?.data;

export { unixTimeToString, millisToMS, millisToHMS, getDayMonth };
