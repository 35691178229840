import {
  BreadcrumbItem,
  useBreadcrumbContext,
} from 'component/context/BreadcrumbContext';
import { useEffect } from 'react';

export const useBreadcrumbItems = (items: BreadcrumbItem[]) => {
  const { setBreadcrumbItems } = useBreadcrumbContext();

  useEffect(() => {
    setBreadcrumbItems(items);
    return () => {
      setBreadcrumbItems(null);
    };
  }, [items]);
};
