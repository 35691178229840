import 'component/loginForm/loginForm.scss';

import { useMutation } from '@tanstack/react-query';
import { API } from 'api/api';
import { AxiosError } from 'axios';
import { Logo } from 'component/appRoot/header/logo/logo';
import React, { FormEvent, useCallback, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';

type TLoginFormProps = { onLogin: () => void };

function LoginForm({ onLogin }: TLoginFormProps) {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const { mutate: login, error } = useMutation<string, AxiosError<string>>({
    mutationFn: () =>
      API.logIn(userName, password).then((result) => result.data),
    onError: () => {
      setPassword('');
    },
    onSuccess: (jwtToken: string) => {
      localStorage.setItem('JWT', jwtToken);
    },
  });

  const onClick = useCallback((event: FormEvent) => {
    event.preventDefault();
    login(undefined, { onSuccess: onLogin });
  }, []);

  // const handleVkLoginClick = () => {
  //   const searchParams = new URLSearchParams({
  //     client_id: '51733966',
  //     redirect_uri: 'https://worldofege.ru/auth',
  //     response_type: 'code',
  //     display: 'page',
  //   });
  //   console.log(`https://oauth.vk.com/authorize?${searchParams}`);
  //   // eslint-disable-next-line no-restricted-globals
  //   location.replace(`https://oauth.vk.com/authorize?${searchParams}`);
  // };

  return (
    <div className="loginForm">
      <Logo className="mb-5" />
      {error ? <Alert variant="danger">{error.response?.data}</Alert> : null}
      <Form onSubmit={onClick}>
        <Form.Group>
          <Form.Control
            size="lg"
            type="login"
            placeholder="Ваш логин"
            required
            className="mb-3"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <Form.Control
            size="lg"
            type="password"
            placeholder="Ваш пароль"
            required
            className="mb-5"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" block size="lg">
          Войти
        </Button>
        {/* <div className="button-vk" onClick={handleVkLoginClick}> */}
        {/*  <div>Войти через VK {'>'}</div> */}
        {/* </div> */}
      </Form>
    </div>
  );
}

export { LoginForm };
