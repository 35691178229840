import { Substrate } from 'component/customComponents/Substrate/Substrate';
import {
  FC,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Container } from 'react-bootstrap';

type SubstrateContextValue = {
  setSubstrateContent: (element: ReactElement) => void;
  isSubstrateOpen: boolean;
  closeSubstrate: () => void;
};

const OverlayContext = createContext<SubstrateContextValue>(
  {} as SubstrateContextValue
);

export const SubstrateContextProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [content, setContent] = useState<ReactElement | null>(null);
  const [isSubstrateOpen, setIsSubstrateOpen] = useState(false);

  const initialOverflow = useRef(document.body.style.overflow);

  useEffect(() => {
    if (isSubstrateOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = initialOverflow.current;
    }
  }, [isSubstrateOpen]);

  const setSubstrateContent = useCallback((element: ReactElement) => {
    setIsSubstrateOpen(true);
    setContent(element);
  }, []);

  const closeSubstrate = useCallback(() => {
    setIsSubstrateOpen(false);
    setContent(null);
  }, []);
  return (
    <OverlayContext.Provider
      value={{
        setSubstrateContent,
        closeSubstrate,
        isSubstrateOpen,
      }}
    >
      {children}
      <Substrate isVisible={isSubstrateOpen} onClick={closeSubstrate}>
        <Container>
          <div onClick={(e) => e.stopPropagation()}>{content}</div>
        </Container>
      </Substrate>
    </OverlayContext.Provider>
  );
};

export const useSubstrateContext = () =>
  useContext<SubstrateContextValue>(OverlayContext);
