import 'react-spring-bottom-sheet/dist/style.css';
import 'component/context/BottomSheetContext/BottomSheet.scss';

import { useWindowDimensions } from 'hooks';
import {
  FC,
  ReactElement,
  Suspense,
  createContext,
  lazy,
  useContext,
  useState,
} from 'react';

type BottomSheetContextValue = {
  setBottomSheetContent: (element: ReactElement) => void;
  closeBottomSheet: () => void;
};

const LazyBottomSheet = lazy(() =>
  import('react-spring-bottom-sheet').then((module) => ({
    default: module.BottomSheet,
  }))
);

const BottomSheetContext = createContext<BottomSheetContextValue>(
  {} as BottomSheetContextValue
);

export const BottomSheetContextProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [content, setContent] = useState<ReactElement | null>(null);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  const { height } = useWindowDimensions();

  const setBottomSheetContent = (element: ReactElement) => {
    setContent(element);
    setIsBottomSheetOpen(true);
  };

  const closeBottomSheet = () => {
    setIsBottomSheetOpen(false);
  };

  return (
    <BottomSheetContext.Provider
      value={{ setBottomSheetContent, closeBottomSheet }}
    >
      {children}
      <Suspense fallback={null}>
        <LazyBottomSheet
          open={isBottomSheetOpen}
          onDismiss={() => setIsBottomSheetOpen(false)}
          scrollLocking
          maxHeight={height * 0.65}
        >
          <div className="bottom-sheet-container">{content}</div>
        </LazyBottomSheet>
      </Suspense>
    </BottomSheetContext.Provider>
  );
};

export const useBottomSheetContext = () =>
  useContext<BottomSheetContextValue>(BottomSheetContext);
