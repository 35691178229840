import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useHistory } from 'react-router';

type TBreadcrumbsProps = {
  className?: string | null;
  items: { name: string; link: string }[];
};

function Breadcrumbs({ items, className = null }: TBreadcrumbsProps) {
  const history = useHistory();
  const onShowArrow = (ind: number) =>
    items.length > 1 && ind === 0 ? (
      <ArrowLeft className="text-white mr-2" />
    ) : null;

  return (
    <div className={`nav-breadcrumb ${className}`}>
      <Breadcrumb className="container" style={{ marginBottom: 0 }}>
        {items.map((item, key) => (
          <Breadcrumb.Item
            key={`${item.name}`}
            onClick={() => history.push(item.link)}
          >
            {onShowArrow(key)}
            {item.name}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
}

export { Breadcrumbs };
