import { Loader } from 'component/customComponents/loader/loader';
import React, { ReactElement, Suspense, lazy, memo } from 'react';

const LazyStudentsWorks = lazy(() =>
  import('./studentsWorks')
    .then((module) => ({ default: module.StudentsWorks }))
    .catch(() => {
      document.location.reload();
      return { default: memo(() => <></>) };
    })
);

export const StudentsWorks = (): ReactElement => (
  <Suspense fallback={<Loader />}>
    <LazyStudentsWorks />
  </Suspense>
);
