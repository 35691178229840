import 'app.scss';

import { useIsFetching } from '@tanstack/react-query';
import { API } from 'api/api';
import { AppRoot } from 'component/appRoot/appRoot';
import { BottomSheetContextProvider } from 'component/context/BottomSheetContext';
import { dialogContext, userContext } from 'component/context/context';
import { OverlayContextProvider } from 'component/context/OverlayContext';
import { SubstrateContextProvider } from 'component/context/SubstrateContext/SubstrateContext';
import { Dialog } from 'component/dialog/dialog';
import { LoginForm } from 'component/loginForm/loginForm';
import { NotificationContainer } from 'component/notificationContainer/notificationContrainer';
import { Journal } from 'pages/journal/journal';
import { VkAuth } from 'pages/vkAuth';
import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Rights } from 'types/rights';
import { useFeatures } from 'utils/featureEnabled';

function CoreApp() {
  const isFetching = useIsFetching();
  const [tokenIsPresent, setTokenIsPresent] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const [showDialog, setShowDialog] = useState(null);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const onDialogInit = useCallback(
    (f) => {
      setShowDialog(f);
    },
    [setShowDialog]
  );

  const removeToken = useCallback(() => {
    localStorage.removeItem('JWT');
    setTokenIsPresent(false);
    setIsValid(null);
  }, []);

  const checkTokenValidity = async () => {
    try {
      const { data } = await API.User.Info.self();
      const { type } = data;
      setUserData({ ...data, type: new Rights(type) });
      setIsValid(true);
    } catch (e) {
      setIsValid(false);
    }
  };

  useEffect(() => {
    const init = async () => {
      const jwt = localStorage.getItem('JWT');
      if (jwt) {
        setTokenIsPresent(true);
        await checkTokenValidity();
      } else {
        setTokenIsPresent(false);
      }
    };
    init();
  }, []);

  switch (tokenIsPresent) {
    case null:
      return <span>Проверка наличия токена...</span>;

    case false:
      return (
        <Switch>
          <Route path="/auth" render={() => <VkAuth />} />
          <Route
            render={() => (
              <LoginForm
                onLogin={() => {
                  setTokenIsPresent(true);
                  checkTokenValidity();
                }}
              />
            )}
          />
        </Switch>
      );

    default:
      break;
  }

  switch (isValid) {
    case null:
      return <span>Проверка валидности токена...</span>;

    case false:
      removeToken();
      return null;

    default:
      break;
  }

  return (
    <div className="App">
      {isFetching ? (
        <Spinner
          size="sm"
          className="fetching-spinner"
          animation="border"
          variant="primary"
        />
      ) : null}
      <userContext.Provider value={userData}>
        <dialogContext.Provider value={showDialog}>
          <SubstrateContextProvider>
            <BottomSheetContextProvider>
              <OverlayContextProvider>
                <Dialog onInit={onDialogInit} />
                {showDialog ? (
                  <AppRoot onLogout={removeToken} />
                ) : (
                  <span>Инициализация</span>
                )}
              </OverlayContextProvider>
            </BottomSheetContextProvider>
          </SubstrateContextProvider>
        </dialogContext.Provider>
      </userContext.Provider>
    </div>
  );
}

function App() {
  useFeatures();
  return (
    <NotificationContainer>
      <Switch>
        <Route
          path="/parentJournal/:id"
          render={({ match }) => {
            const { params } = match;
            const { id } = params;
            if (!id.match(/^\d+$/)) {
              return <span>Некорректная ссылка</span>;
            }
            return <Journal groupId={parseInt(id, 10)} />;
          }}
        />

        <Route render={() => <CoreApp />} />
      </Switch>
    </NotificationContainer>
  );
}

export { App };
