import 'component/appRoot/footer/footer.scss';

import vk from 'assets/images/common/vk.svg';
import youtube from 'assets/images/common/youTube.svg';
import React from 'react';
import { Container } from 'react-bootstrap';

function Footer() {
  return (
    <div className="footer">
      <Container>
        <div className="footerContentContainer">
          <div className="footerItemContainer">
            <div>
              &copy;
              {new Date().getFullYear()}
            </div>
            <div>
              <a href="mailto:info@expert-ege.ru">info@expert-ege.ru</a>
              <br />
              <span>+7 981 70 90 100 </span>
              (с 10:00 до 21:00)
            </div>
          </div>
          <div className="footerItemContainer">
            <div className="socialLinks">
              <a
                href="https://youtube.com/@user-tg5yq6om4v?si=zUU3kbK8AAoURIYj"
                target="_blank"
              >
                <img alt="img" src={youtube} />
              </a>
              <a href="https://vk.com/expertege" target="_blank">
                <img alt="img" src={vk} />
              </a>
            </div>
            <div>
              Московский проспект д.&nbsp;2/6
              <br />
              подъезд 2, этаж 3, офисы 5, 7
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export { Footer };
