import { useLayoutEffect, useState } from 'react';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'exl' | 'xxl' | '';

export const breakpoints: Record<number, Breakpoint> = {
  0: 'xs',
  576: 'sm', // > 600 Одна колонка в контейнере, боттомщит
  768: 'md', // > 1024 Две колонки в контейнере, боковая панель скрыта
  992: 'lg', // > 1364 Две колонки в контейнере, боковая панель открыта
  1200: 'xl',
  1420: 'exl',
  1960: 'xxl',
};

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>('');
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  } | null>(null);

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    if (windowSize?.width) {
      if (windowSize?.width > 0 && windowSize?.width < 576) {
        setBreakpoint(breakpoints[0]);
      }
      if (windowSize.width >= 576 && windowSize.width < 768) {
        setBreakpoint(breakpoints[576]);
      }
      if (windowSize.width >= 768 && windowSize.width < 992) {
        setBreakpoint(breakpoints[768]);
      }
      if (windowSize.width >= 992 && windowSize.width < 1200) {
        setBreakpoint(breakpoints[992]);
      }
      if (windowSize.width >= 1200 && windowSize.width < 1960) {
        setBreakpoint(breakpoints[1200]);
      }
      if (windowSize.width >= 1200 && windowSize.width < 1420) {
        setBreakpoint(breakpoints[1200]);
      }
      if (windowSize.width >= 1420 && windowSize.width < 1960) {
        setBreakpoint(breakpoints[1420]);
      }
      if (windowSize.width >= 1960) {
        setBreakpoint(breakpoints[1960]);
      }
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize?.width]);

  return breakpoint;
};
