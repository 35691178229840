import 'component/customComponents/Substrate/Substrate.scss';

import React, { FC } from 'react';

type SubstrateProps = {
  isVisible: boolean;
  children?: React.ReactNode;
  onClick: () => void;
};

export const Substrate: FC<SubstrateProps> = ({
  isVisible,
  children,
  onClick,
}) => (
  <div
    className={`${isVisible ? 'expandedOverlay' : 'collapsedOverlay'}`}
    onClick={onClick}
  >
    {children && <div className="w-100">{children}</div>}
  </div>
);
