import { Loader } from 'component/customComponents/loader/loader';
import React, {
  ComponentProps,
  ReactElement,
  Suspense,
  lazy,
  memo,
} from 'react';

const LazyJournal = lazy(() =>
  import('./journal')
    .then((module) => ({ default: module.Journal }))
    .catch(() => {
      document.location.reload();
      return { default: memo((): JSX.Element => <></>) };
    })
);

export const Journal = (
  props: ComponentProps<typeof LazyJournal>
): ReactElement => (
  <Suspense fallback={<Loader />}>
    <LazyJournal {...props} />
  </Suspense>
);
