import 'component/customComponents/overlay/overlay.scss';

import React, { FC } from 'react';

type OverlayProps = {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  styles?: React.CSSProperties;
};

export const Overlay: FC<OverlayProps> = ({
  className,
  children,
  onClick,
  styles,
}) => (
  <div onClick={onClick} className={`overlayComp ${className}`} style={styles}>
    <div>{children}</div>
  </div>
);
