import 'pages/journal/journalRouter/journalRouter.scss';

import { userContext } from 'component/context/context';
import { PageTitle } from 'component/customComponents/pageTitle/pageTitle';
import { useBreadcrumbItems } from 'hooks/useBreadcrumbItems';
import { Journal } from 'pages/journal/journal';
import { StudentJournalData } from 'pages/journal/journal/StudentJournalData';
import { StudentJournalsList } from 'pages/journal/journal/StudentJournalsList';
import { JournalGroupSelector } from 'pages/journal/journalGroupSelector';
import React, { memo, useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

/*
 * Элемент, перенаправляющий на журнал или на выбор группы, если она не указана в пути
 * */
const breadcrumb = [
  {
    link: '/main',
    name: 'Предметы',
  },
  {
    link: '/journal',
    name: 'Журнал',
  },
];

const JournalRouter = memo(() => {
  const { url } = useRouteMatch();
  const { type } = useContext(userContext);
  const role = type?.role;
  useBreadcrumbItems(breadcrumb);

  return (
    <>
      {role !== 'student' && <PageTitle name="Журнал" />}

      <Switch>
        <Route
          path={`${url}/:id`}
          render={({ match }) => {
            const { params } = match;
            const { id } = params;
            if (!id.match(/^\d+$/)) {
              return <Redirect to={`${url}`} />;
            }
            return role === 'student' ? (
              <StudentJournalData groupId={parseInt(id, 10)} />
            ) : (
              <Journal groupId={parseInt(id, 10)} />
            );
          }}
        />
        <Route
          render={() =>
            role === 'student' ? (
              <StudentJournalsList />
            ) : (
              <JournalGroupSelector />
            )
          }
        />
      </Switch>
    </>
  );
});

export { JournalRouter };
