import 'component/dialog/dialog.scss';

import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const Dialog = ({ onInit }) => {
  const [hidden, setHidden] = useState(true);
  const [element, setElement] = useState(null);
  const [resolve, setResolve] = useState(null);

  const reset = () => {
    setHidden(true);
    setElement(null);
    setResolve(null);
  };

  const resolveDialog = useCallback(
    (answer) => {
      resolve(answer);
      reset();
    },
    [resolve]
  );

  const showDialog = useCallback(
    (el) => {
      setElement(el);
      setHidden(false);
      return new Promise((r) => {
        setResolve(() => r);
      });
    },
    [setElement, setResolve]
  );

  useEffect(() => {
    onInit(() => showDialog);
  }, [onInit, showDialog]);

  return (
    <Modal show={!hidden} onHide={resolveDialog} centered>
      <div className="close-modal" onClick={() => reset()} aria-hidden="true">
        {' '}
      </div>
      {element ? React.cloneElement(element, { resolve: resolveDialog }) : null}
    </Modal>
  );
};

Dialog.propTypes = {
  onInit: PropTypes.func.isRequired,
};

export { Dialog };
