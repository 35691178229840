import { FC, ReactElement, createContext, useContext, useState } from 'react';

export type BreadcrumbItem = {
  name: string;
  link: string;
};

type BreadcrumbContextValue = {
  breadcrumbsItems: BreadcrumbItem[] | null;
  setBreadcrumbItems: (items: BreadcrumbItem[] | null) => void;
};

const BreadcrumbContext = createContext<BreadcrumbContextValue>(
  {} as BreadcrumbContextValue
);

export const BreadcrumbContextProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [items, setItems] = useState<BreadcrumbItem[] | null>(null);

  const setBreadcrumbItems = (items: BreadcrumbItem[] | null) => {
    setItems(items);
  };

  return (
    <BreadcrumbContext.Provider
      value={{ breadcrumbsItems: items, setBreadcrumbItems }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumbContext = () =>
  useContext<BreadcrumbContextValue>(BreadcrumbContext);
