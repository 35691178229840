import {
  TNotificationContextValue,
  TNotificationTypes,
  TUserData,
} from 'component/context/types';
import React, { ReactElement } from 'react';

const userContext = React.createContext<Partial<TUserData>>({});

const notificationContext = React.createContext<TNotificationContextValue>(
  (notification: string, type: TNotificationTypes) => {
    console.error(
      `Tried to call notification when context is not yet available. Notification: ${notification} ${type}`
    );
  }
);
const dialogContext = React.createContext(
  <T extends any>(element: ReactElement) => {
    console.error(
      `Tried to call dialog when context is not yet available. Dialog: ${element}`
    );
    return Promise.resolve() as Promise<T>;
  }
);

export { userContext, notificationContext, dialogContext /* lessonContext */ };
