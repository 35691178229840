import { useMemo } from 'react';
import { useLocation } from 'react-router';

export function useQueryParams<T extends Record<string, string>>() {
  const { search } = useLocation();

  return useMemo(
    () =>
      decodeURIComponent(search)
        .slice(1)
        .split('&')
        .reduce((acc, cur) => {
          const [key, value] = cur.split('=');
          return { ...acc, [key]: value };
        }, {}) as unknown as T,
    [search]
  );
}
