import React from 'react';

function Logo({ className = null }: { className?: string | null }) {
  return (
    <div className={`d-flex align-items-center ${className}`}>
      <svg
        width="245"
        height="36"
        viewBox="0 0 245 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M196.462 13.7882L196.82 22.4902H195.467L195.149 14.3445H194.87L193.001 20.9007C192.921 21.2186 192.722 21.3378 192.444 21.3378H191.29C190.971 21.3378 190.773 21.1789 190.693 20.9007L188.823 14.3445H188.584L188.266 22.4902H186.953L187.311 13.7882C187.311 13.4305 187.51 13.2319 187.868 13.2319H189.34C189.659 13.2319 189.818 13.3908 189.897 13.669L191.449 19.0729C191.568 19.51 191.648 19.9074 191.767 20.3047H192.006C192.085 19.8676 192.205 19.4703 192.324 19.0332L193.876 13.6292C193.955 13.3511 194.154 13.1921 194.473 13.1921H195.945C196.263 13.2319 196.462 13.4305 196.462 13.7882Z"
          fill="#325895"
        />
        <path
          d="M206.726 22.49H205.413V15.9734C205.413 15.6158 205.413 15.2184 205.493 14.8608H205.413C205.294 15.2184 205.095 15.6158 204.936 15.8939L201.037 22.4502H199.645V13.2317H200.918V19.6688C200.918 20.0661 200.878 20.4237 200.838 20.8211H200.918C201.077 20.4635 201.236 20.1059 201.435 19.788L205.294 13.2317H206.687L206.726 22.49Z"
          fill="#325895"
        />
        <path
          d="M215.996 16.053C215.996 18.3577 215.24 18.9934 213.132 18.9934C212.376 18.9934 211.699 18.9537 211.103 18.8742V22.4901H209.75V13.2716C210.784 13.1524 211.461 13.1524 212.973 13.1524C215.24 13.1126 215.996 13.7484 215.996 16.053ZM214.644 16.053C214.644 14.6623 214.246 14.3047 212.933 14.3047C212.217 14.3047 211.62 14.3047 211.103 14.3047V17.8411H212.933C214.206 17.8014 214.644 17.4438 214.644 16.053Z"
          fill="#325895"
        />
        <path
          d="M228.329 21.3774L228.369 22.4105C227.136 22.5297 225.743 22.5297 224.391 22.5297C223.237 22.5297 222.561 21.8939 222.561 20.8608V14.9006C222.561 13.8675 223.277 13.2317 224.391 13.2317C225.704 13.2317 227.136 13.2317 228.369 13.3509L228.329 14.384H224.629C224.112 14.384 223.873 14.6224 223.873 15.2582V17.086H227.772V18.1588H223.873V20.5032C223.873 21.139 224.072 21.3774 224.629 21.3774H228.329Z"
          fill="#325895"
        />
        <path
          d="M236.526 13.3511L236.486 14.3842H233.144C232.507 14.3842 232.269 14.6226 232.269 15.2981V22.4902H230.916V15.02C230.916 13.9471 231.672 13.1921 232.667 13.1921C233.979 13.1921 235.332 13.1921 236.526 13.3511Z"
          fill="#325895"
        />
        <path
          d="M244.999 17.841C244.999 21.3774 244.005 22.6092 241.259 22.6092C240.066 22.6092 239.35 22.5297 238.594 22.2913L238.713 21.2582C239.469 21.3774 240.106 21.3774 241.259 21.3774C242.97 21.3774 243.607 20.5429 243.607 18.0396H239.708V17.086H243.607C243.487 15.0198 242.851 14.3045 241.259 14.3045C240.145 14.3045 239.628 14.3443 238.793 14.4237L238.673 13.3906C239.469 13.1919 240.066 13.1125 241.259 13.1125C244.044 13.0727 244.999 14.3443 244.999 17.841Z"
          fill="#325895"
        />
        <path
          d="M68.3116 18.6755C68.3116 25.2715 66.4018 27.6556 61.0706 27.6556C58.7232 27.6556 57.3705 27.4967 55.8984 27.0199L56.2167 24.6358C57.649 24.8344 58.9221 24.8742 61.1104 24.8742C63.9351 24.8742 64.9696 23.5629 65.0491 19.4305H57.8479V17.0861H65.0093C64.7706 13.6291 63.7362 12.5166 61.1104 12.5166C59.0017 12.5166 57.9275 12.5563 56.3361 12.6755L56.0576 10.2914C57.5694 9.89405 58.763 9.73511 61.1104 9.73511C66.4018 9.69537 68.3116 12.0795 68.3116 18.6755Z"
          fill="#325895"
        />
        <path
          d="M84.6232 27.3377H81.321C79.4908 21.7748 78.9338 19.5099 76.0295 19.5099H74.5574C74.6768 20.1059 74.7166 20.6622 74.7166 21.2185V27.3377H71.6133V10.0132H74.7563V15.0993C74.7563 15.7748 74.6768 16.4503 74.5972 17.1258H76.0693C78.8145 17.1258 79.5704 14.543 81.0027 10.0132H84.1855C82.4748 15.1787 81.8382 17.8807 78.6553 18.2781V18.3973C81.9973 18.7152 82.5543 21.2979 84.6232 27.3377Z"
          fill="#325895"
        />
        <path
          d="M98.4308 10.2515L98.1125 12.6356C96.5609 12.5164 95.5264 12.4767 93.4178 12.4767C90.5532 12.4767 89.6779 13.9469 89.6779 18.6754C89.6779 23.4039 90.5532 24.8741 93.4178 24.8741C95.6458 24.8741 96.9985 24.8343 98.4706 24.6356L98.7491 26.98C97.1974 27.4568 95.8049 27.6158 93.4178 27.6158C88.2059 27.6158 86.3359 25.2714 86.3359 18.6356C86.3359 11.9999 88.2059 9.65552 93.4178 9.65552C95.7651 9.69525 96.9189 9.85419 98.4308 10.2515Z"
          fill="#325895"
        />
        <path
          d="M114.702 27.3377H111.558V12.437H104.715V27.3377H101.572V10.0132H114.702V27.3377Z"
          fill="#325895"
        />
        <path
          d="M129.859 24.7948L129.979 27.1789C127.711 27.4173 124.926 27.4173 122.34 27.4173C120.032 27.4173 118.68 26.1458 118.68 24.1193V13.2319C118.68 11.2054 120.072 9.93384 122.34 9.93384C124.926 9.93384 127.711 9.93384 129.979 10.1325L129.859 12.5564H123.056C122.141 12.5564 121.823 12.914 121.823 14.0266V17.0862H128.905V19.4703H121.823V23.3246C121.823 24.3974 122.141 24.7948 123.056 24.7948H129.859Z"
          fill="#325895"
        />
        <path
          d="M145.377 15.3376C145.377 19.7482 143.905 20.9005 139.807 20.9005C138.574 20.9005 137.38 20.821 136.306 20.7018V27.2978H133.123V10.0131C135.112 9.81439 136.465 9.77466 139.409 9.77466C143.905 9.77466 145.377 10.9667 145.377 15.3376ZM142.194 15.3376C142.194 12.9535 141.518 12.3574 139.33 12.3574C138.216 12.3574 137.181 12.3574 136.266 12.3972V18.3972H139.33C141.518 18.3574 142.194 17.7614 142.194 15.3376Z"
          fill="#325895"
        />
        <path
          d="M160.654 12.6754H155.283V27.3376H152.1V12.6754H146.809V9.97339H160.654V12.6754Z"
          fill="#325895"
        />
        <path d="M173.068 1.31128V36" stroke="#ED4340" strokeMiterlimit="10" />
        <path
          d="M25.4239 24.5166C24.3497 24.9935 23.0765 24.9935 22.0023 24.5166L9.94727 19.3511V25.5895C9.94727 26.265 10.3053 26.8213 10.8226 27.1392L11.4193 27.3776L22.0023 31.9074C23.0765 32.3842 24.3497 32.3842 25.4239 31.9074L36.0466 27.4173L36.4047 27.2584C36.9617 26.9405 37.3596 26.3444 37.3596 25.6292V19.3908L25.4239 24.5166Z"
          fill="#ED4340"
        />
        <path
          d="M46.032 9.21854L25.423 0.357616C24.3488 -0.119205 23.0757 -0.119205 22.0015 0.357616L1.9495 8.9404C1.75057 9.01987 1.63121 9.21854 1.63121 9.41722C1.63121 9.69536 1.86993 9.93377 2.14842 9.93377H21.4842C21.9219 9.17881 22.7176 8.70199 23.6327 8.70199C25.0252 8.70199 26.1392 9.81457 26.1392 11.2053C26.1392 12.596 25.0252 13.7086 23.6327 13.7086C22.7176 13.7086 21.9219 13.1921 21.4842 12.4768H3.73985H2.5065C1.98928 12.4768 1.55164 12.7947 1.35271 13.2318C1.27314 13.3907 1.23335 13.5497 1.23335 13.7086V25.2318C0.517213 25.6291 0 26.4238 0 27.3775C0 28.7682 1.114 29.8808 2.5065 29.8808C3.89899 29.8808 5.01299 28.7682 5.01299 27.3775C5.01299 26.4636 4.49578 25.6689 3.77964 25.2318V14.5828C3.77964 14.4636 3.89899 14.3444 4.01835 14.3444C4.05814 14.3444 4.09792 14.3444 4.09792 14.3841L22.0015 22.053C23.0757 22.5298 24.3488 22.5298 25.423 22.053L46.032 13.1921C47.7428 12.3974 47.7428 9.97351 46.032 9.21854Z"
          fill="#325895"
        />
      </svg>
    </div>
  );
}

export { Logo };
