import { OptionQuestionSubtype } from 'component/extendedTest/types/optionQuestionSubtype';
import { SimpleQuestionSubtype } from 'component/extendedTest/types/simpleQuestionSubtype';

const ElementType = {
  EXTRA: 'extra',
  OPTION_QUESTION: 'optionQuestion',
  SIMPLE_QUESTION: 'simpleQuestion',
  EXTENDED_QUESTION: 'extendedQuestion',
};

const representations = {};
representations[ElementType.EXTRA] = 'Дополнительные материалы';
representations[ElementType.OPTION_QUESTION] = 'Вопрос с опциями';
representations[ElementType.SIMPLE_QUESTION] = 'Простой вопрос';
representations[ElementType.EXTENDED_QUESTION] = 'Расширенный вопрос';

const getDefaultElement = (type) => {
  switch (type) {
    case ElementType.EXTRA:
      return {
        text: '',
        attachments: [],
      };

    case ElementType.OPTION_QUESTION:
      return {
        text: '',
        clarification: { text: '', attachments: [] },
        subtype: OptionQuestionSubtype.SINGLE_CHOICE,
        maxPoints: 0,
        options: [],
        attachments: [],
      };

    case ElementType.SIMPLE_QUESTION:
      return {
        text: '',
        clarification: { text: '', attachments: [] },
        maxPoints: 0,
        subtype: SimpleQuestionSubtype.DIRECT_COMPARISON,
        answer: [],
        attachments: [],
      };

    case ElementType.EXTENDED_QUESTION:
      return {
        text: '',
        clarification: { text: '', attachments: [] },
        criteria: [],
        attachments: [],
      };

    default:
      throw Error(`Unexpected type: ${type}`);
  }
};

const getDefaultTemplate = (type) => {
  switch (type) {
    case ElementType.EXTRA:
      return {};

    case ElementType.OPTION_QUESTION:
      return {
        subtype: OptionQuestionSubtype.SINGLE_CHOICE,
        maxPoints: 0,
      };

    case ElementType.SIMPLE_QUESTION:
      return {
        maxPoints: 0,
        subtype: SimpleQuestionSubtype.DIRECT_COMPARISON,
      };

    case ElementType.EXTENDED_QUESTION:
      return {
        criteria: [],
      };

    default:
      throw Error(`Unexpected type: ${type}`);
  }
};

export { ElementType };
export { representations, getDefaultElement, getDefaultTemplate };
