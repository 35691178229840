import 'component/notificationContainer/notificationContainer.scss';

import { notificationContext } from 'component/context/context';
import { TNotificationTypes } from 'component/context/types';
import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';
import { Toast } from 'react-bootstrap';
import ReactDOM from 'react-dom';

const notificationContainer = document.getElementById('notifications');

function NotificationContainer({ children }: PropsWithChildren) {
  const lastId = useRef(0);
  const [notifications, setNotifications] = useState<
    {
      message: string;
      type?: TNotificationTypes;
      id: number;
    }[]
  >([]);

  const dropNotification = (id: number) => () => {
    setNotifications(
      notifications.filter((notification) => notification.id !== id)
    );
  };

  const addNotification = useCallback(
    (message: string, type: TNotificationTypes) => {
      setNotifications((notifications) => [
        ...notifications,
        { id: lastId.current, message, type },
      ]);
      lastId.current += 1;
    },
    []
  );

  return (
    <>
      {ReactDOM.createPortal(
        <>
          {notifications.map(({ id, message, type = '' }) => (
            <Toast
              key={id}
              autohide
              onClose={dropNotification(id)}
              className={type ? `toast--${type}` : ''}
            >
              <Toast.Header>
                <strong className="mr-auto">Эксперт мир ЕГЭ</strong>
              </Toast.Header>
              <Toast.Body>{message}</Toast.Body>
            </Toast>
          ))}
        </>,
        notificationContainer as HTMLElement
      )}
      <notificationContext.Provider value={addNotification}>
        {children}
      </notificationContext.Provider>
    </>
  );
}

export { NotificationContainer };
