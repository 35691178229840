import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function Loader({
  loaderText = null,
  className = null,
}: {
  loaderText?: string | null;
  className?: string | null;
}) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${className}`}
    >
      <Spinner animation="border" variant="primary" />
      <div className="ml-3 text-primary small">{loaderText}</div>
    </div>
  );
}

export { Loader };
