import { Loader } from 'component/customComponents/loader/loader';
import React, { Suspense, lazy } from 'react';

const LazyTemplate = lazy(() =>
  import('./template')
    .then((module) => ({ default: module.Template }))
    .catch(() => {
      document.location.reload();
      return { default: () => <></> };
    })
);

export const Template = (props) => (
  <Suspense fallback={<Loader />}>
    <LazyTemplate {...props} />
  </Suspense>
);
