const SimpleQuestionSubtype = {
  DIRECT_COMPARISON: 'directComparison',
  SEQUENCE: 'sequence',
  SET: 'set',
};

const representations = {
  [SimpleQuestionSubtype.DIRECT_COMPARISON]: 'Прямое сравнение',
  [SimpleQuestionSubtype.SEQUENCE]: 'Символы в определенной последовательности',
  [SimpleQuestionSubtype.SET]: 'Символы в произвольной последовательности',
};

export { SimpleQuestionSubtype };
export { representations };
