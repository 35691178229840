import Cookies from 'js-cookie';
import { useQueryParams } from 'utils/useQueryParams';

const features: Record<string, boolean> = {
  // featureNotifications: false,
};

export const useFeatures = () => {
  const queryParams = useQueryParams();
  const cookies = Cookies.get();
  Object.keys(cookies).forEach((key: string) => {
    const value = cookies[key];
    if (
      key.startsWith('feature') &&
      !features[key] &&
      (value === 'true' || value === 'false')
    )
      features[key] = value === 'true';
  });
  Object.keys(queryParams).forEach((key: string) => {
    const value = queryParams[key];
    if (
      key.startsWith('feature') &&
      !features[key] &&
      (value === 'true' || value === 'false' || value === 'tmp')
    ) {
      if (value === 'tmp') {
        Cookies.set(key, 'true', {
          expires: new Date(new Date().getTime() + 60 * 60 * 1000),
        });
        features[key] = true;
      } else {
        features[key] = value === 'true';
      }
    }
  });
};

export const featureEnabled = (featureName: string) =>
  features[featureName] || false;
