import { Loader } from 'component/customComponents/loader/loader';
import React, { ReactElement, Suspense, lazy } from 'react';

const LazyStudentJournalList = lazy(() =>
  import('./StudentJournal')
    .then((module) => ({ default: module.StudentJournalsList }))
    .catch(() => {
      document.location.reload();
      return { default: (): JSX.Element => <></> };
    })
);

export const StudentJournalsList = (): ReactElement => (
  <Suspense fallback={<Loader />}>
    <LazyStudentJournalList />
  </Suspense>
);
