import { Loader } from 'component/customComponents/loader/loader';
import React, { Suspense, lazy } from 'react';

const LazyTest = lazy(() =>
  import('./test')
    .then((module) => ({ default: module.Test }))
    .catch(() => {
      document.location.reload();
      return { default: () => <></> };
    })
);

export const Test = (props) => (
  <Suspense fallback={<Loader />}>
    <LazyTest {...props} />
  </Suspense>
);
