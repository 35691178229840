import { Loader } from 'component/customComponents/loader/loader';
import React, { ReactElement, Suspense, lazy } from 'react';

const LazyQuestionsList = lazy(() =>
  import('./questionsList')
    .then((module) => ({ default: module.QuestionsList }))
    .catch(() => {
      document.location.reload();
      return { default: () => <></> };
    })
);

export const QuestionsList = (): ReactElement => (
  <Suspense fallback={<Loader />}>
    <LazyQuestionsList />
  </Suspense>
);
