import { Loader } from 'component/customComponents/loader/loader';
import React, { ReactElement, Suspense, lazy } from 'react';

const LazyUserExamStats = lazy(() =>
  import('./userExamStats')
    .then((module) => ({ default: module.UserExamStats }))
    .catch(() => {
      document.location.reload();
      return { default: () => <></> };
    })
);

export const UserExamStats = (): ReactElement => (
  <Suspense fallback={<Loader />}>
    <LazyUserExamStats />
  </Suspense>
);
