const OptionQuestionSubtype = {
  SINGLE_CHOICE: 'singleChoice',
  MULTIPLE_CHOICE: 'multipleChoice',
};

const representations = {
  [OptionQuestionSubtype.SINGLE_CHOICE]: 'Единственный ответ',
  [OptionQuestionSubtype.MULTIPLE_CHOICE]: 'Множественный ответ',
};

export { OptionQuestionSubtype };
export { representations };
