import { Loader } from 'component/customComponents/loader/loader';
import React, { ReactElement, Suspense, lazy, memo } from 'react';

const LazyJournalGroupSelector = lazy(() =>
  import('./journalGroupSelector')
    .then((module) => ({ default: module.JournalGroupSelector }))
    .catch(() => {
      document.location.reload();
      return { default: memo(() => <></>) };
    })
);

export const JournalGroupSelector = (): ReactElement => (
  <Suspense fallback={<Loader />}>
    <LazyJournalGroupSelector />
  </Suspense>
);
