import { Overlay } from 'component/customComponents/overlay';
import { useWindowDimensions } from 'hooks';
import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

type OverlayContextValue = {
  setOverlayContent: (element: ReactElement) => void;
  isOverlayOpen: boolean;
  closeOverlay: () => void;
};

const OverlayContext = createContext<OverlayContextValue>(
  {} as OverlayContextValue
);

const transitionDuration = 0.5;

export const OverlayContextProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [content, setContent] = useState<ReactElement | null>(null);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [isTransitionInProgress, setIsTransitionInProgress] = useState(false);
  const { width } = useWindowDimensions();

  const setOverlayContent = (element: ReactElement) => {
    setContent(element);
    setIsOverlayOpen(true);
    setIsTransitionInProgress(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
    setTimeout(() => {
      setIsTransitionInProgress(false);
    }, transitionDuration * 1000);
  };
  const isVisible = isOverlayOpen || isTransitionInProgress;

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isVisible]);

  return (
    <OverlayContext.Provider
      value={{ setOverlayContent, closeOverlay, isOverlayOpen }}
    >
      {children}
      <Overlay
        styles={{
          position: 'fixed',
          right: `${width}`,
          visibility: isVisible ? 'visible' : 'hidden',
          transition: `transform ${transitionDuration}s ease`,
          transform: isOverlayOpen
            ? `translateX(0px)`
            : `translateX(${width}px)`,
        }}
        onClick={closeOverlay}
      />
      <div
        style={{
          position: 'fixed',
          visibility: isVisible ? 'visible' : 'hidden',
          transition: `transform ${transitionDuration}s ease`,
          zIndex: 1000,
          transform: !isOverlayOpen
            ? `translateX(0px)`
            : `translateX(${width}px)`,
        }}
      >
        {content}
      </div>
    </OverlayContext.Provider>
  );
};

export const useOverlayContext = () =>
  useContext<OverlayContextValue>(OverlayContext);
