import 'component/customComponents/pageTitle/pageTitle.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

type TPageTitleProps = {
  name: string | undefined;
  className?: string | null;
};

function PageTitle({ name, className = null }: TPageTitleProps) {
  return (
    <Container className="p-xl-1">
      <div className={`h1 pageTitle my-3 ${className}`}>{name}</div>
    </Container>
  );
}

export { PageTitle };
