import 'component/appRoot/NavigationMenu/NavigationMenu.scss';

import { PAGES } from 'constants/availablePages';

import payment from 'assets/images/menuItems/payment.svg';
import subjects from 'assets/images/menuItems/subjects.svg';
import { userContext } from 'component/context/context';
import React, { FC, useContext } from 'react';
import { Badge } from 'react-bootstrap';
import { AppIndicator, PersonWorkspace } from 'react-bootstrap-icons';
import { Menu, MenuItem } from 'react-pro-sidebar';
import { NavLink, useLocation } from 'react-router-dom';
import { RightValues } from 'types/rights';

type NavigationMenuProps = {
  uncheckedTests?: number;
  uncheckedStudentsTests?: number;
  onItemClick: () => void;
};

export const NavigationMenu: FC<NavigationMenuProps> = ({
  uncheckedTests,
  uncheckedStudentsTests,
  onItemClick,
}) => {
  const { type } = useContext(userContext);
  const location = useLocation();
  const isCoursePage =
    location.pathname.includes('/course') ||
    location.pathname.includes('/lesson') ||
    location.pathname.includes('/main');

  return (
    <Menu
      menuItemStyles={{
        button: {
          [`&.active`]: {
            backgroundColor: '#CAE6FF',
            color: '#2a5a97',
          },
        },
      }}
      className="sidebar-menu"
    >
      <MenuItem
        className="menu-item"
        style={{
          backgroundColor: isCoursePage ? '#CAE6FF' : 'inherit',
        }}
        icon={
          <img
            alt="img"
            src={subjects}
            style={{ width: '20px', height: '20px' }}
          />
        }
        component={<NavLink to="/main" />}
        key={'/main'}
        onClick={onItemClick}
      >
        Предметы
      </MenuItem>
      {PAGES.map((page) => {
        const Icon = page.icon;
        return (
          page.renderFor.includes(type?.role) &&
          page.isShow && (
            <MenuItem
              className={`menu-item`}
              icon={Icon && <Icon width="20px" height="18px" />}
              component={<NavLink to={page.link} />}
              key={page.link}
              onClick={onItemClick}
            >
              {page.name}
              {page.link === '/studentPersonalAccount' &&
                uncheckedStudentsTests && (
                  <Badge className="unchecked-badge" pill variant="danger">
                    {uncheckedStudentsTests}
                  </Badge>
                )}
            </MenuItem>
          )
        );
      })}
      {type?.hasRightsOf(RightValues.Teacher) && (
        <MenuItem
          className="menu-item"
          icon={<PersonWorkspace width="20px" height="20px" color="#389AF4" />}
          component={<NavLink to="/uncheckedTests" />}
          key={'/uncheckedTests'}
          onClick={onItemClick}
        >
          На проверку
          {uncheckedTests ? (
            <Badge className="unchecked-badge" pill variant="danger">
              {uncheckedTests}
            </Badge>
          ) : (
            <></>
          )}
        </MenuItem>
      )}
      {!type?.hasRightsOf(RightValues.Teacher) && (
        <MenuItem
          className="menu-item"
          icon={
            <img
              alt="img"
              src={payment}
              style={{ width: '20px', height: '20px' }}
            />
          }
          component={
            <a href="https://expert-ege.ru/stranica-oplaty" target="_blank" />
          }
          key={'stranica-oplaty'}
          onClick={onItemClick}
        >
          Оплата курса
        </MenuItem>
      )}
      <MenuItem
        className="menu-item"
        icon={<AppIndicator width="20px" height="20px" color="#389AF4" />}
        component={<NavLink to="/technical-requirements" />}
        key={'/technical-requirements'}
        onClick={onItemClick}
      >
        Техническая информация
      </MenuItem>
    </Menu>
  );
};
